
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import initials from 'initials'

  import { Employee } from '@/entities/hr'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'

@Component({})
  export default class EmployeeAvatar extends FilesProcess {
  @Prop({ type: Object, required: true }) user!: Employee
  @Prop({ type: Boolean, default: false }) suggested!: boolean
  @Prop({ type: String, default: 'blue darken-1' }) color!: string
  @Prop({ type: Number, default: null }) idProcess: number

  mainPhoto = []

  get initials (): string | string[] {
    const { name, email } = this.user
    return initials(name || email)
  }

  get available (): boolean | undefined {
    const { available } = this.user
    return available
  }

  get availability (): boolean {
    const { available } = this
    return available !== undefined
  }

  get userHasPhoto () {
    const { photo } = this.user
    const { mainPhoto } = this

    if (mainPhoto?.length) {
      return true
    }

    if (photo instanceof Array) {
      return photo?.length
    }

    return photo
  }

  get userPhoto () {
    const { photo } = this.user
    const { mainPhoto } = this

    if (mainPhoto?.length) {
      return mainPhoto[0].file.uri
    }

    let customPhoto
    if (photo instanceof Array && photo?.length) {
      customPhoto = photo[0]?.main
    } else {
      customPhoto = photo?.main
    }

    return customPhoto
  }

  get userAndProcess () {
    const { user, idProcess } = this

    return {
      user,
      idProcess,
    }
  }

  @Watch('userAndProcess', { immediate: true, deep: true })
  async onUserChange (val) {
    if (val?.user?.id && val.idProcess) {
      const person = await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { employee: { id: { _eq: val?.user?.id } } },
      })

      this.mainPhoto = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: person[0]?.id } },
            { parameter: { process: { id: { _eq: val.idProcess } } } },
          ],
        },
        force: true,
      })
    }
  }
  }
